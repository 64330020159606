import { useRef, useState } from 'react'
import cookie from 'cookie'
import { useRouter } from 'next/router'
import AdButlerPlacement from '../AdButlerPlacement'
import { setBannerVisibility } from 'src/redux/actions'
import { getBannerVisibility } from 'src/redux/selectors'
import { useSelector, useDispatch } from 'react-redux'

import styles from './styles.module.scss'

const getCookieValue = ({ key, cookies, options, defaultValue }) => {
  const value = cookie.parse(cookies || '', options)
  return value[key] ? value[key] !== '0' : defaultValue
}

const cookieKey = 'isTopLeaderboardShown'
const initialValue = true

// const getInitialValue = () => {
//   if (typeof window === 'undefined' || typeof document === 'undefined')
//     return initialValue

//   return getCookieValue({
//     key: cookieKey,
//     cookies: document.cookie,
//     defaultValue: initialValue,
//   })
// }

const TopLeaderboardBanner = () => {
  // const [shown, setShown] = useState(getInitialValue())
  // const [shown, setShown] = useState(true)
  const dispatch = useDispatch()
  const shown = useSelector(getBannerVisibility)
  const router = useRouter()
  const isShare = router.asPath.includes('getScreen')
  const wrapperRef = useRef()

  const hideBanner = () => {
    document.cookie = cookie.serialize('isTopLeaderboardShown', '0', {
      path: '/',
      maxAge: 60 * 60 * 4,
    })
    dispatch(setBannerVisibility(false))
  }

  const onEmpty = () => dispatch(setBannerVisibility(false))

  return (
    shown &&
    !isShare && (
      <span className={styles.topLeaderboard} ref={wrapperRef}>
        <button className={styles.close} onClick={hideBanner}>
          Close
        </button>
        <AdButlerPlacement
          place='562858'
          className={styles.placement}
          onEmpty={onEmpty}
          adjustHeight={true}
          forceProxy={true}
        />
      </span>
    )
  )
}

export default TopLeaderboardBanner
