import React, { useState } from 'react'
import clsx from 'clsx'
import useWidth from 'src/hooks/useWidth'
import { useOverlayTriggerState } from '@react-stately/overlays'
import styles from './styles.module.scss'
import { Dialog } from 'src/components/Dialog'
import { Currency } from './components/Currency'
import Image from 'next/image'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/utils/localStorage'
import { setSharedCurrency } from 'src/redux/thunks'
import { useDispatch } from 'react-redux'
import { intl } from 'src/i18n'
import { getRemoteMapData } from 'src/redux/thunks'
import { ENTITY } from 'src/consts'

export const CurrencyLanguageModal = ({ buttonClass }) => {
  const dispatch = useDispatch()
  const { isMobile } = useWidth()
  const dialog = useOverlayTriggerState({})
  const [currencyActive, setCurrencyActive] = useState(
    getLocalStorageItem('currency') || 'USD'
  )

  const handleSavePreference = () => {
    setLocalStorageItem('currency', currencyActive)
    dispatch(setSharedCurrency(currencyActive))

    dispatch(getRemoteMapData({ entity: ENTITY.COIN }))

    dialog.close()
  }

  const handleCancelPreference = () => {
    setCurrencyActive(getLocalStorageItem('currency') || 'USD')
    dialog.close()
  }
  return (
    <>
      <span
        onClick={dialog.open}
        className={clsx({
          [styles.isMobile]: isMobile,
          [buttonClass]: true,
        })}
      >
        <Image
          src={`/assets/icons/icon-world.svg`}
          width='22px'
          height='22px'
          alt='world'
        />
        {currencyActive}
      </span>
      <Dialog
        dismissable
        headerless
        padding={false}
        onClose={dialog.close}
        open={dialog.isOpen}
        title=''
        dialogClassName={styles.dialogClassName}
      >
        <div className={clsx(styles.container)}>
          <div className={styles.header}>
            {intl.t('currencyLanguageModal.title')}
          </div>

          <Currency
            currencyActive={currencyActive}
            setCurrencyActive={setCurrencyActive}
          />
          <div className={styles.footer}>
            <span
              className={clsx(styles.button, styles.buttonCancel)}
              onClick={() => handleCancelPreference()}
            >
              {intl.t('currencyLanguageModal.cancel')}
            </span>
            <span
              className={clsx(styles.button, styles.buttonSave)}
              onClick={() => handleSavePreference()}
            >
              {intl.t('currencyLanguageModal.save')}
            </span>
          </div>
        </div>
      </Dialog>
    </>
  )
}
